import {qspost} from '@/plugins/axios'

/*
 * offer主列表
 * @constructor jack
 * */
export const getAdminOfferList = (page, limit, searchName, staff_type) => qspost('/index/Offer/getAdminOfferList', {
    page,
    limit,
    searchName, staff_type
})


/*
 * 全职 发起 离职
 * @constructor jack
 * */
export const departStaffFullTime = (offerId, xingming, gangwei, tijiaoriqi, lizhiriqi, yinggongzuotianshu, shijigongzuotianshu, lizhiyuanying, lizhiyuanying_qita) => qspost('/index/Depart/departStaffFullTime', {
    offerId,
    xingming,
    gangwei,
    tijiaoriqi,
    lizhiriqi,
    yinggongzuotianshu,
    shijigongzuotianshu,
    lizhiyuanying,
    lizhiyuanying_qita
})

/*
 * 全职 发起 离职信息获取
 * @constructor jack
 * */
export const departStaffFullTimeShow = (offerId) => qspost('/index/Depart/departStaffFullTimeShow', {
    offerId,
})


/*
 * 全职 离职协议
 * @constructor jack
 * */
export const departStaffFullTimeAgreement = (offerId,
                                             xingming,
                                             gangwei,
                                             lishubumen,
                                             zhijieshangji,
                                             ContractSignDate,
                                             ContractEndDate,
                                             ContractTerminationDate,
                                             EndSalaryReleaseMonth,
                                             ContractTerminationSignDate) => qspost('/index/Depart/departStaffFullTimeAgreement', {
    offerId,
    xingming,
    gangwei,
    lishubumen,
    zhijieshangji,
    ContractSignDate,
    ContractEndDate,
    ContractTerminationDate,
    EndSalaryReleaseMonth,
    ContractTerminationSignDate
})

/*
 * 全职 离职协议 详情
 * @constructor jack
 * */
export const departStaffFullTimeAgreementShow = (offerId) => qspost('/index/Depart/departStaffFullTimeAgreementShow', {
    offerId,
})

/*
 * 全职 发起 全职 离职协议回传
 * @constructor jack
 * */
export const departStaffFullTimeAgreementReply = (offerId, file) => qspost('/index/Depart/departStaffFullTimeAgreementReply', {
    offerId, file
})

/*
 * 全职 离职交接
 * @constructor jack
 * */
export const departStaffFullTimeHandover = (offerId, xingming, gangwei, resignSubDate, lizhiriqi, socialInsuranceEnd, anyAgreement, lizhiyuanying, resignSalary, resignSalaryReleaseMonth, resignSignDate) => qspost('/index/Depart/departStaffFullTimeHandover', {
    offerId,
    xingming,
    gangwei,
    resignSubDate,
    lizhiriqi,
    socialInsuranceEnd,
    anyAgreement,
    lizhiyuanying,
    resignSalary,
    resignSalaryReleaseMonth,
    resignSignDate,

})

/*
 * 全职 离职交接 详情
 * @constructor jack
 * */
export const departStaffFullTimeHandoverShow = (offerId) => qspost('/index/Depart/departStaffFullTimeHandoverShow', {
    offerId,
})

/*
 * 全职 离职交接 回传
 * @constructor jack
 * */
export const departStaffFullTimeHandoverReply = (offerId, file) => qspost('/index/Depart/departStaffFullTimeHandoverReply', {
    offerId, file
})

/*
 * 全职 离职薪酬
 * @constructor jack
 * */
export const departStaffFullTimePay = (offerId, xingming, ruzhiriqi, lizhiriqi, dangyueyingchuqin, dangyueshiichuqin, jibengongzi, kaoqinkoukuan, lizhiyingfa, weiyongnianjiahetiaoxiu, sickLeaveDays, xinzi_qita, gongjijindaikou, geshuidaikou, yongpinpeichang, caiwuqiankuan, qiankuan_qita, weiyuekoukuan, shifaxinzi, resignSignDate) => qspost('/index/Depart/departStaffFullTimePay', {
    offerId,
    xingming,
    ruzhiriqi,
    lizhiriqi,
    dangyueyingchuqin,
    dangyueshiichuqin,
    jibengongzi,
    kaoqinkoukuan,
    lizhiyingfa,
    weiyongnianjiahetiaoxiu,
    sickLeaveDays,
    xinzi_qita,
    gongjijindaikou,
    geshuidaikou,
    yongpinpeichang,
    caiwuqiankuan,
    qiankuan_qita,
    weiyuekoukuan,
    shifaxinzi,
    resignSignDate

})

/*
 * 全职 离职薪酬 详情
 * @constructor jack
 * */
export const departStaffFullTimePayShow = (offerId) => qspost('/index/Depart/departStaffFullTimePayShow', {
    offerId,
})

/*
 * 全职 离职薪酬 回传
 * @constructor jack
 * */
export const departStaffFullTimePayReply = (offerId, file) => qspost('/index/Depart/departStaffFullTimePayReply', {
    offerId, file
})

/*
 * 全职 离职 证明
 * @constructor jack
 * */
export const departStaffFullTimeProve = (offerId, xingming, shenfenzhenghao, shoujihao, jiatingdizhi, ruzhiriqi, lizhiriqi, resignSignDate,) => qspost('/index/Depart/departStaffFullTimeProve', {
    offerId,
    xingming,
    shenfenzhenghao,
    shoujihao,
    jiatingdizhi,
    ruzhiriqi,
    lizhiriqi,
    resignSignDate,
})

/*
 * 全职 离职证明 详情
 * @constructor jack
 * */
export const departStaffFullTimeProveShow = (offerId) => qspost('/index/Depart/departStaffFullTimeProveShow', {
    offerId,
})

/*
 * 全职 离职证明回传
 * @constructor jack
 * */
export const departStaffFullTimeProveReply = (offerId, file) => qspost('/index/Depart/departStaffFullTimeProveReply', {
    offerId, file
})


/*
 * 实习 离职
 * @constructor jack
 * */
export const departStaffPractice = (offerId, xingming, gangwei, tijiaoriqi, lizhiriqi) => qspost('/index/Depart/departStaffPractice', {
    offerId,
    xingming,
    gangwei,
    tijiaoriqi,
    lizhiriqi,

})

/*
 * 实习 离职
 * @constructor jack
 * */
export const departStaffPracticeShow = (offerId) => qspost('/index/Depart/departStaffPracticeSwho', {
    offerId
})


/*
 * 实习 解除实习协议
 * @constructor jack
 * */
export const departStaffPracticeAgreement = (offerId, xingming, shenfenzhenghao, xianjudizhi, internJoinDate, internResignDate, internTerminationDate, resignSalaryReleaseMonth, internResignSignDate,) => qspost('/index/Depart/departStaffPracticeAgreement', {
    offerId,
    xingming,
    shenfenzhenghao,
    xianjudizhi,
    internJoinDate,
    internResignDate,
    internTerminationDate,
    resignSalaryReleaseMonth,
    internResignSignDate,

})

/*
 * 实习 解除实习协议 详情
 * @constructor jack
 * */
export const departStaffPracticeAgreementShow = (offerId) => qspost('/index/Depart/departStaffPracticeAgreementShow', {
    offerId,
})

/*
 * 实习 解除实习协议 回传
 * @constructor jack
 * */
export const departStaffPracticeAgreementReply = (offerId, file) => qspost('/index/Depart/departStaffPracticeAgreementReply', {
    offerId, file
})


/*
 * 实习 当月考勤 回传
 * @constructor jack
 * */
export const departStaffPartTimePayReply = (offerId, file) => qspost('/index/Depart/departStaffPartTimePayReply', {
    offerId, file
})
export const departStaffPracticePayReply = (offerId, file) => qspost('/index/Depart/departStaffPracticePayReply', {
    offerId, file
})


/*
 * 实习 实习证明
 * @constructor jack
 * */
export const departStaffPracticeProve = (offerId, xingming, gangweimingcheng, internJoinDate, internResignDate, internCertificationSignDate,) => qspost('/index/Depart/departStaffPracticeProve', {
    offerId,
    xingming,
    gangweimingcheng,
    internJoinDate,
    internResignDate,
    internCertificationSignDate,
})

/*
 * 实习 实习证明 详情
 * @constructor jack
 * */
export const departStaffPracticeProveShow = (offerId) => qspost('/index/Depart/departStaffPracticeProveShow', {
    offerId,
})

/*
 * 实习 实习证明 回传
 * @constructor jack
 * */
export const departStaffPracticeProveReply = (offerId, file) => qspost('/index/Depart/departStaffPracticeProveReply', {
    offerId, file
})


/*
 * 兼职 离职
 * @constructor jack
 * */
export const departStaffPartTime = (offerId, xingming, gangwei, tijiaoriqi, lizhiriqi) => qspost('/index/Depart/departStaffPartTime', {
    offerId,
    xingming,
    gangwei,
    tijiaoriqi,
    lizhiriqi,
})

/*
 * 兼职 离职 详情
 * @constructor jack
 * */
export const departStaffPartTimeShow = (offerId) => qspost('/index/Depart/departStaffPartTimeShow', {
    offerId
})


/*
 * 兼职 终止协议
 * @constructor jack
 * */
export const departStaffPartTimeAgreement = (offerId,
                                             xingming,
                                             IDNo,
                                             ConsultantAddress,
                                             ConsultantJoinDate,
                                             ConsultantResignDate,
                                             ConsultantTerminationDate,
                                             ResignSalaryReleaseMonth,
                                             ConsultantResignSignDate,) => qspost('/index/Depart/departStaffPartTimeAgreement', {
    offerId,
    xingming,
    IDNo,
    ConsultantAddress,
    ConsultantJoinDate,
    ConsultantResignDate,
    ConsultantTerminationDate,
    ResignSalaryReleaseMonth,
    ConsultantResignSignDate,
})

/*
 * 兼职 终止协议 详情
 * @constructor jack
 * */
export const departStaffPartTimeAgreementShow = (offerId) => qspost('/index/Depart/departStaffPartTimeAgreementShow', {
    offerId
})

/*
 * 兼职 终止协议 回传
 * @constructor jack
 * */
export const departStaffPartTimeAgreementReply = (offerId, file) => qspost('/index/Depart/departStaffPartTimeAgreementReply', {
    offerId, file
})


/*
 * 兼职 当月考勤 回传
 * @constructor jack
 * */
export const departStaffPartPayReply = (offerId, file) => qspost('/index/Depart/departStaffPartPayReply', {
    offerId, file
})


/*
 * 设置离职状态为 完结 已离职
 * @constructor jack
 * */
export const departSetOver = (offerId, fid) => qspost('/index/Depart/departSetOver', {
    offerId, fid
})

/*
 * 取消离职
 * @constructor jack
 * */
export const deleteDepart = (offerId) => qspost('/index/Depart/deleteDepart', {
    offerId
})

/*
 * 检测当前用户有没有下级人员
 * @constructor jack
 * */
export const checkMyChildStaff = (offerId) => qspost('/index/Depart/checkMyChildStaff', {
    offerId
})
